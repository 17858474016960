import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const IndexLogin = () => import("./components/IndexLogin.vue");
const FamilyFriends = () => import("./components/FamilyFriends.vue");
const IndexRegister = () => import("./components/IndexRegister.vue");
const ThankYou = () => import("./components/ThankYou.vue");
const LogIn = () => import("./components/LogIn.vue");
const NotFound = () => import("./components/NotFound.vue");
const ToStart = () => import("./components/ToStart.vue");
const Closed = () => import("./components/Closed.vue");
const DEFAULT_TITLE = 'Ed Sheeran: +-=÷x Tour';

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "*",
      component: IndexLogin,
      // component: NotFound
      // component: ToStart
    },
    {
      path: '/family-and-friends',
      name: 'family-and-friends',
      component: FamilyFriends,
      beforeEnter: (to, from, next) => {
        if (!store.state.auth.user) {
          next('/'); // Redirect to login page or another route if the user is not authenticated
        } else {
          next(); // Continue to the route if the user is authenticated
        }
      }
    },
    {
      path: '/register',
      name: 'register',
      component: IndexRegister
    },
    {
      path: '/login',
      name: 'login',
      component:LogIn
    },
    {
      path: '/thank-you',
      name: 'thank-you',
      component: ThankYou,
      // beforeEnter: (to, from, next) => {
        // if (!store.state.auth.user) {
        //   next('/'); // Redirect to login page or another route if the user is not authenticated
        // }else {
        //   next(); // Continue to the route if the user is authenticated
        // }
      // }
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: NotFound
    },
  ],
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
